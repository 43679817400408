import React, { useState } from "react";

import { useStore } from "@state/store";
import AccountTemplate from "@components/account/accountTemplate";
import ShopifyOrders from "@shopify/orders";
import ShopifyOrder from "@shopify/order";

export default function Orders() {
  const { shopify } = useStore();
  const [order, setOrder] = useState<number | null>(null);

  return (
    <AccountTemplate shopify={shopify} handle="orders">
      {order ? (
        <ShopifyOrder
          shopify={shopify}
          orderState={order}
          setOrder={setOrder}
        />
      ) : (
        <ShopifyOrders shopify={shopify} setOrder={setOrder} />
      )}
    </AccountTemplate>
  );
}
